"use client";

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SignInForm from "../../sections/Login";
import { Stack, Theme, useTheme } from "@mui/material";
import { useAuthModalStore } from "@/stores/authModalStore";
import { shallow } from "zustand/shallow";
import { useConfigStore } from "@/stores/configStore";
import { alpha } from "@mui/system";
import styled from "@emotion/styled";
import CloseButton from "./CloseButton";
import { Logo } from "../logo";

const Content = styled(Box)<{ theme?: Theme }>(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  [theme.breakpoints.up("md")]: {
    width: "800px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "450px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "90%",
  },
  height: "auto",
  maxHeight: "80%",
  overflow: "scroll",
  background: theme.gradient[300],
  boxShadow: theme.shadows[6],
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${alpha(theme.palette.primary.main, 0.05)}`,
  padding: theme.spacing(2),
}));

export default function BasicModal() {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const [open, setOpen] = useAuthModalStore(
    (state) => [state.openLoginModal, state.setOpenLoginModal],
    shallow
  );
  const handleClose = () => setOpen(false);
  const handleFinish = () => {
    handleClose();
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: alpha(theme.palette.secondary.main, 0.7),
              backdropFilter: "blur(18px)",
            },
          },
        }}
      >
        <Content>
          <CloseButton onClick={handleClose} />
          <Stack alignItems={"center"}>
            {/* <Logo src={config?.logo || ""} height={theme.config.logo.height} /> */}
            <Typography
              mt={2}
              variant="h5"
              textAlign={"center"}
              sx={{ color: (theme) => theme.gradient[900] }}
            >
              เข้าสู่ระบบ
            </Typography>
            <SignInForm onFinish={handleFinish} />
          </Stack>
        </Content>
      </Modal>
    </div>
  );
}

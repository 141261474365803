import React, { Fragment } from "react";
import numeral from "numeral";

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/Button";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DialogContent from "@mui/material/DialogContent";

// mui icon
import Close from '@mui/icons-material/Close';

interface ResponsiveDialogProps {
  wheelData: any | null;
  open: boolean;
  closeDialog: () => void;
}

// type
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '90%',
  maxWidth: '400px',
  height: 'auto',
  bgcolor: "background.paper",
  boxShadow: 24,
};
const TermsDialog: React.FC<ResponsiveDialogProps> = ({
  wheelData,
  open,
  closeDialog
}) => {
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={closeDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            borderRadius: (theme) => theme.shape.borderRadius + "px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 100 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>ยอดเล่นสะสม</TableCell>
                  {/* <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>ประเภท</TableCell> */}
                  <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>รางวัล</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[
                  {
                    'turnover': '500 ขึ้นไป',
                    'type': 'เงิน',
                    'reward': '฿ 1~255'
                  },
                  {
                    'turnover': '1,500 ขึ้นไป',
                    'type': 'เงิน',
                    'reward': '฿ 5~555'
                  },
                  {
                    'turnover': '5,000 ขึ้นไป',
                    'type': 'ทอง',
                    'reward': '฿ 15~2,555'
                  },
                  {
                    'turnover': '15,000 ขึ้นไป',
                    'type': 'เพชร',
                    'reward': '฿ 35~5,888'
                  }
                ].map((row: any) => (
                  <TableRow
                    key={row.turnover}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {/* <TableCell align="center" component="th" scope="row" sx={{ color: (theme) => theme.gradient[900] }}>
                            {row.created_at}
                          </TableCell> */}
                    <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{row.turnover}</TableCell>
                    {/* <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{row.type}</TableCell> */}
                    <TableCell align="center" sx={{ color: (theme) => theme.gradient[900] }}>{row.reward}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default TermsDialog;

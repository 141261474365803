"use client";

import * as React from "react";
import { styled } from "@mui/material/styles";
import { Divider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Avatar, Chip, Stack, Typography } from "@mui/material";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";
import { Logo } from "@/components/logo";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Footer() {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);

  return (
    <Box sx={{ flexGrow: 1, mt: "50px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider sx={{ background: '#213743', borderWidth: '1px' }} />
        </Grid>
        <Grid item xs={12}>
          <Item>
            <Stack>
              {/* <Typography
                mb={1}
                variant="h5"
                color={(theme) => theme.gradient[700] || theme.palette.primary.main}
              >
                ธนาคารที่รองรับ
              </Typography> */}
              <Stack
                spacing={2}
                direction="row"
                useFlexGap
                flexWrap="wrap"
                sx={{ alignItems: { xs: "center" }, justifyContent: { xs: "center" } }}
              >
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/KBANK.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/SCB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/KTB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/BAY.png?auto=compress&q=50&fm=webp"
                />
                {/* <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TRUEWALLET.png?auto=compress&q=50&fm=webp"
                /> */}
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/GSB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/BBL.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/BAAC.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/KKB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/OSK.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TTB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TISGO.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/UOB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/CITI.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/LNH.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/CIMB.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/TCR.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/MIZUHO.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/SCBT.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/ICBC.png?auto=compress&q=50&fm=webp"
                />
                <Avatar
                  alt="Avatar"
                  src="https://d13bpib24yechf.cloudfront.net/_ty1/ISBT.png?auto=compress&q=50&fm=webp"
                />
              </Stack>
            </Stack>
          </Item>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ background: '#213743', borderWidth: '1px' }} />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box py={`${theme.config.logo.appBarSpacing}px`}>
              <Logo
                src={config?.logo || ""}
                height={theme.config.logo.appBarHeight}
              />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Item sx={{
              padding: 0,
              color: 'rgba(255, 255, 255, 0.7)',
              maxWidth: '1024px'
            }}>
              © 2024 Lek77.com | All Rights Reserved.
            </Item>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Item sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              maxWidth: '1024px'
            }}>
              Lek77 นำเสนอผลิตภัณฑ์เกมส์คุณภาพสูงที่หลากหลายแก่ผู้เล่นของเรา ทีมช่วยเหลือลูกค้าของเราพร้อมให้บริการคุณตลอด 24 ชั่วโมง
              ข้อมูลส่วนบุคคลทั้งหมดจะได้รับการปฏิบัติและเก็บรักษาไว้อย่างเข้มงวดและเป็นความลับที่สุด
            </Item>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box, IconButton, Theme, Typography } from "@mui/material";
import Iframe from "react-iframe";
import { Icon } from "@iconify/react";
import { red } from "@mui/material/colors";
import styled from "@emotion/styled";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  open: true | false;
  close: () => void;
  gameUrl: string | "";
};

const CloseIconButton = styled(IconButton)(({ theme }: { theme?: Theme }) => ({
  padding: '4px !important',
  width: '48px !important',
  height: '48px !important',
  flexDirection: 'column !important' as any,
  backgroundColor: 'rgba(0,0,0,.6) !important',
  color: theme?.palette.common.white,
  boxShadow: theme?.shadows[4],
  border: '.015rem solid hsla(0,0%,100%,.65) !important',
  transition: "all 0.12s ease-in-out",
  "&:hover": {
    transform: "scale(1.08)",
    border: '.015rem solid hsla(0,0%,100%,.65) !important',
    backgroundColor: 'rgba(0,0,0,.6) !important',
  },
  "&:active": {
    transform: "scale(0.98)",
    border: '.015rem solid hsla(0,0%,100%,.65) !important',
    backgroundColor: 'rgba(0,0,0,.6) !important',
  },
}));

export default function GameLauncherDialog({ open, close, gameUrl }: Props) {
  const [config] = useConfigStore((state) => [state.config], shallow);
  const handleClose = () => {
    close();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Box height={"100%"} position="relative">
        {
          config?.is_open_back_button && (
            <Box position="absolute" top={0} left={0} zIndex={1} p={1}>
              <CloseIconButton onClick={close} color="error" size="medium">
                <Icon icon="flat-color-icons:home" width={20} height={20} />
                <Typography variant='subtitle2' color="white" sx={{ fontSize: '0.6rem' }}>
                  ล็อบบี้
                </Typography>
              </CloseIconButton>
            </Box>
          )
        }
        <Box position="relative" height="100%">
          {gameUrl && (
            <Iframe
              url={gameUrl}
              frameBorder={0}
              width="100%"
              height="100%"
              display="block"
              position="relative"
            />
          )}
        </Box>
      </Box>
    </Dialog >
  );
}

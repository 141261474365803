"use client";

import React, { CSSProperties, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import {
    Box,
    Grid,
    CircularProgress,
    useMediaQuery,
    useTheme,
    Skeleton,
    Typography,
    styled,
    Card,
    CardMedia,
    CardMediaProps,
    Stack,
    IconButton,
    Button,
} from "@mui/material";
import useBannerSlider from "@/hooks/fetchers/useBannerSlider";
import "./style.css";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import { ArrowBack, ArrowBackIosNew, ArrowBackIosNewSharp, ArrowForward, ArrowForwardIos } from "@mui/icons-material";
import numeral from "numeral";
import { useLaunch } from "@/hooks";

type Props = {
    section: any
    router: any
    isLoggedIn: any
    isActive: any
    activePromotionPlatform: any
    handleRightMenuClick: any
    launchGame: any
    isGameLaunchLoading: any
    setCategory: any
};

const indicatorStyles: CSSProperties = {
    background: "#fff",
    display: "inline-block",
    margin: "0 8px",
    cursor: 'pointer'
};

const Item = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

const ItemImage = styled(CardMedia)<CardMediaProps>(({ theme }) => ({
    borderRadius: "16px",
    transition: "all 0.12s ease-in-out",
    "&:hover": {
        transform: "scale(1.08)",
        cursor: "pointer",
    },
}));

export default function SectionList(props: Props) {
    const theme = useTheme();
    const [selected, setSelected] = useState(0)
    const [isLoggedIn] = useAuthStore((state) => [state.user], shallow);
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const size = isMobile ? props?.section?.minSize : props?.section?.maxSize
    const chunkList = props?.section?.list
        .reduce((acc: any, _: any, i: any) => {
            if (i % size === 0) acc.push(props?.section?.list.slice(i, i + size))
            return acc
        }, [])
    const isShowAllButton = !isLoggedIn
        ? false
        : props?.isActive
            ? props?.activePromotionPlatform.includes(props?.section.path || "")
                ? props?.section.isShowAll
                : false
            : props?.section.isShowAll

    return (
        <Grid
            id={`category-${props?.section.label}`}
            key={`category-${props?.section.label}`}
            item
            xs={12}
            sx={{ pt: 3, pb: 0 }}
        >
            <Item
                sx={{
                    pb: 1,
                    display: 'flex',
                    justifyContent: {
                        xs: 'space-between',
                        md: 'space-between',
                        lg: 'space-between'
                    },
                }}
            >
                <Typography
                    variant="h6"
                    ml={1}
                    sx={{ color: "white" }}
                >
                    {props?.section.label || "~"}
                </Typography>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    {
                        isShowAllButton && (
                            <Button
                                size="large"
                                variant={'outlined'}
                                color={'success'}
                                onClick={() => {
                                    if (props?.section.type === 'provider') {
                                        props?.setCategory(props?.section.path)
                                        window.scrollTo(0, 1);
                                        return
                                    }

                                    props?.router.push(`lobbies/${props?.section.path}`);
                                }}
                                sx={{
                                    fontSize: '0.875rem',
                                    marginRight: '8px',
                                    padding: '0',
                                    borderColor: 'transparent',
                                    backgroundColor: '#2d4454',
                                    color: '#ffffffb3',
                                    "&:hover": {
                                        borderColor: 'transparent',
                                        backgroundColor: '#2d4454',
                                        color: '#ffffffb3',
                                    },
                                }}
                            >
                                ทั้งหมด
                            </Button>
                        )}
                    <IconButton
                        style={{
                            border: `1px solid ${selected === 0 ? 'transparent' : 'transparent'}`,
                            backgroundColor: '#2d4454',
                            height: "28px",
                            width: "28px",
                            padding: '0 8px',
                            borderRadius: '2rem 0 0 2rem',
                            marginRight: '2px'
                        }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {
                            setSelected(prevState => prevState - 1 >= 0 ? prevState - 1 : prevState)
                        }}
                        color="inherit"
                        disabled={selected === 0}
                    >
                        <ArrowBackIosNew style={{ fontSize: "18px" }} />
                    </IconButton>
                    <IconButton
                        style={{
                            border: `1px solid ${selected + 1 === chunkList.length ? 'transparent' : 'transparent'}`,
                            backgroundColor: '#2d4454',
                            height: "28px",
                            width: "28px",
                            borderRadius: '0 2rem 2rem 0'
                        }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={() => {
                            setSelected(prevState => prevState + 1 <= chunkList.length ? prevState + 1 : prevState)
                        }}
                        color="inherit"
                        disabled={selected + 1 === chunkList.length}
                    >
                        <ArrowForwardIos style={{ fontSize: "18px" }} />
                    </IconButton>
                </Stack>
            </Item>
            <Carousel
                centerMode
                selectedItem={selected}
                onChange={(index: number) => selected !== index && setSelected(index)}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                showIndicators={false}
                centerSlidePercentage={isMobile ? 100 : 90}
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={50}
            >
                {chunkList
                    .map((item: any, index: number) => {
                        return (
                            <Box key={index} sx={{ flexGrow: 1, marginRight: ((index + 1) !== chunkList.length && !isMobile) ? '8px' : '0px' }}>
                                <Grid container spacing={props?.section?.spacing || 1}>
                                    {
                                        item.map((game: any) => {
                                            return (
                                                <Grid key={game.game} item xs={props?.section?.minGrid || 4} md={props?.section?.maxGrid || 2}>
                                                    <Item>
                                                        <Card
                                                            sx={{
                                                                backgroundImage: 'none',
                                                                boxShadow: 'none',
                                                                cursor: 'pointer',
                                                                borderRadius: "16px",
                                                                transition: "all 0.12s ease-in-out",
                                                                "&:hover": {
                                                                    transform: "scale(1.04)",
                                                                    cursor: "pointer",
                                                                },
                                                                height: "auto",
                                                                filter: props?.isActive && !props?.activePromotionPlatform.includes(game?.platform || "")
                                                                    ? "grayscale(90%)"
                                                                    : props?.isGameLaunchLoading
                                                                        ? "grayscale(90%)"
                                                                        : "grayscale(0%)",
                                                            }}
                                                            onClick={() => {
                                                                if (!props?.isLoggedIn) {
                                                                    return props?.handleRightMenuClick();
                                                                }

                                                                if (props?.isGameLaunchLoading) return
                                                                if (props?.isActive && !props?.activePromotionPlatform.includes(game?.platform || "")) return

                                                                if (props?.section.type === 'provider') {
                                                                    if (props?.section.name === 'casino' || props?.section.name === 'sport') {
                                                                        game?.onClick()
                                                                        return
                                                                    } else {
                                                                        props?.router.push(`lobbies/${game?.game_id}`);
                                                                        return
                                                                    }
                                                                }

                                                                props?.launchGame(
                                                                    game?.game_id || "",
                                                                    isMobile
                                                                );
                                                            }}
                                                        >
                                                            <ItemImage
                                                                component="img"
                                                                image={game?.image}
                                                                title={game?.game}
                                                                style={{
                                                                    width: "100%"
                                                                }}
                                                            />
                                                        </Card>
                                                        {
                                                            props?.section?.isShowActive && (
                                                                <Stack
                                                                    direction={"row"}
                                                                    alignItems={"center"}
                                                                    justifyContent={"start"}
                                                                >
                                                                    <Typography
                                                                        className="neonTextSuccess"
                                                                        variant="h6"
                                                                        fontWeight={"500"}
                                                                        align="center"
                                                                        color={'#1de50d'}
                                                                        sx={{
                                                                            mt: 1,
                                                                            pl: '3px',
                                                                            fontSize: '0.5rem',
                                                                            fontWeight: '400'
                                                                        }}
                                                                    >
                                                                        {`●`}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            mt: 1,
                                                                            mx: 0.5,
                                                                            fontSize: '0.75rem',
                                                                            fontWeight: '600',
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                        }}
                                                                        variant="body2"
                                                                        color={"white"}
                                                                    >
                                                                        {
                                                                            game?.active
                                                                                ? numeral(game?.active).format("0,0")
                                                                                : ""
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            mt: 1,
                                                                            fontSize: '0.75rem',
                                                                            fontWeight: '400',
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                        }}
                                                                        variant="subtitle2"
                                                                        color={"#b1bad3"}
                                                                    >
                                                                        {"ออนไลน์"}
                                                                    </Typography>
                                                                </Stack>
                                                            )
                                                        }
                                                    </Item>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Box>
                        )
                    })}
            </Carousel>
        </Grid >
    )
}

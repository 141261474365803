"use client";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { usePromotionStore } from "@/stores/promotionStore";
import { shallow } from "zustand/shallow";
import usePromotionList from "@/hooks/fetchers/usePromotionList";
import { useSnackbar } from "notistack";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  FormHelperText,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
  Grid,
  TextField,
  CircularProgress,
  Stack,
  Snackbar,
  Chip,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useMe from "@/hooks/fetchers/useMe";
import { useAppModalStore } from "@/stores/appModalStore";
import usePromotionHistory from "@/hooks/fetchers/usePromotionHistory";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function PromotionsList() {
  const { refresh } = useMe();
  const { refresh: refreshPromotion } = usePromotionHistory();
  const [accept] = usePromotionStore((state) => [state.accept], shallow);
  const { data: promotionList, isLoading: isPromotionListLoading } =
    usePromotionList();
  const [open, setOpen] = useState(false);
  const [promotionid, setPromotionId] = useState("");
  const [promotionname, setPromotionName] = useState("");
  const [promotiondesc, setPromotionDesc] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [setAppModalOpen, setData] = useAppModalStore(
    (state) => [state.setOpen, state.setData],
    shallow
  );

  function showDialog(id: string) {
    var options: any = {
      id: `${id}`,
    };

    var filtered = promotionList?.filter(function (obj: any) {
      return Object.keys(options).some(function (key: any) {
        if (key != "color") {
          return obj[key] == options[key];
        } else {
          return options[key].some(function (s: any) {
            return s == obj[key];
          });
        }
      });
    });

    if (filtered != undefined) {
      setPromotionId(filtered[0].id);
      setPromotionName(filtered[0].name);
      setPromotionDesc(filtered[0].description);
      setOpen(true);
    }
  }

  function acceptPromotion() {
    accept(promotionid)
      .then((res: any) => {
        if (res?.errMessage) {
          enqueueSnackbar(res.errMessage, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        } else {
          enqueueSnackbar("รับโปรโมชั่นสำเร็จ", {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
          refresh();
          refreshPromotion();
          setAppModalOpen(false);
          handleClose();
        }
      })
      .catch((err) => {
        enqueueSnackbar("This is a success message!", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      });
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  };

  if (isPromotionListLoading) {
    return (
      <>
        <Grid sx={{ textAlign: "center" }}>
          <CircularProgress></CircularProgress>
        </Grid>
      </>
    );
  } else {
    return (
      <Box>
        <Box mb={3}>
          {/* <Typography variant="h5" align="center" sx={{ color: (theme) => theme.gradient[900] }}>
            โปรโมชั่น
          </Typography> */}

          <Divider>
            <Chip
              label="โปรโมชั่น"
              color="primary"
              size="medium"
              style={{ fontSize: "18px", fontWeight: 700 }}
            />
          </Divider>
        </Box>

        <Grid
          container
          rowSpacing={2}
        >
          <Grid item xs={12}>
            <Card sx={{ color: (theme) => theme.gradient[900] }}>
              {/* <CardMedia component="img" image={'https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/828x274_wheel.jpg'} alt={'กงล้อนำโชค'} /> */}
              <CardMedia component="img" image={'https://images-platform.sgp1.cdn.digitaloceanspaces.com/promotion/bc_7.jpg'} alt={'กงล้อนำโชค'} /> 
            </Card>
            <CardContent>
              <Box textAlign="center">
                <Button
                  variant="contained"
                  onClick={() => { setData('wheel'); }}
                >
                  รายละเอียด
                </Button>
              </Box>
            </CardContent>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ color: (theme) => theme.gradient[900] }}>
              {/* <CardMedia component="img" image={'https://images-platform.sgp1.cdn.digitaloceanspaces.com/general/828x274.jpg'} alt={'คืนยอดเสีย'} /> */}
              <CardMedia component="img" image={'https://images-platform.sgp1.cdn.digitaloceanspaces.com/promotion/bc_6.jpg'} alt={'คืนยอดเสีย'} />
            </Card>
            <CardContent>
              <Box textAlign="center">
                <Button
                  variant="contained"
                  onClick={() => { setData('refund'); }}
                >
                  รายละเอียด
                </Button>
              </Box>
            </CardContent>
          </Grid>
        </Grid>

        <Grid
          container
          rowSpacing={3}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 1, sm: 1, md: 1 }}
        >
          {promotionList?.map((item) => (
            <Grid item xs={1} sm={4} md={4}>
              <Card sx={{ color: (theme) => theme.gradient[900] }}>
                <CardMedia component="img" image={item?.img} alt={item?.name} />
                {/* <CardContent>
                  <Box textAlign="center">
                    <Typography gutterBottom>{item?.name}</Typography>
                    <Button
                      variant="contained"
                      onClick={() => showDialog(item?.id)}
                    >
                      รายละเอียด
                    </Button>
                  </Box>
                </CardContent> */}
              </Card>
              <CardContent>
                <Box textAlign="center">
                  <Typography gutterBottom>{item?.name}</Typography>
                  <Button
                    variant="contained"
                    onClick={() => showDialog(item?.id)}
                  >
                    รายละเอียด
                  </Button>
                </Box>
              </CardContent>
            </Grid>
          ))}
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2, color: (theme) => theme.gradient[900] }} id="customized-dialog-title">
              {promotionname}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Typography
                gutterBottom
                sx={{ color: (theme) => theme.gradient[900] }}
              >
                <p dangerouslySetInnerHTML={{ __html: promotiondesc }}></p>
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                autoFocus
                onClick={() => acceptPromotion()}
              >
                รับโปรโมชั่น
              </Button>
              <Button
                variant="outlined"
                autoFocus
                onClick={handleClose}
              >
                ปิด
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </Grid>
      </Box >
    );
  }
}

import { apiPath } from "@/configs/apiPath";
import { fetcherAuth } from "@/libs/utils";
import useSWR from "swr";

type GameProvider = {
  category: string;
  name: string;
  code: string;
  status: boolean;
  display: boolean;
  image: string;
  is_lobby: boolean;
  games_lobby: any[];
  code_betflik: string | null;
};

type UseGameProvidersReturn = {
  data: { lobby_list: any, provider_list: GameProvider[]} | null;
  isUfaEnabled: boolean;
  isBetflixEnabled: boolean;
  isLoading: boolean;
  isError: any;
};

export default function useFetchProvidersList(): UseGameProvidersReturn {
  const { data, error } = useSWR<{ lobby_list: any, provider_list: GameProvider[]}>(
    apiPath.game.getProvider,
    fetcherAuth
  );

  let isUfaEnabled =
    (Array.isArray(data) &&
      data?.some((game) => game.code !== "" && game.code === "UFA")) ??
    false;

  let isBetflixEnabled =
    (Array.isArray(data) &&
      data?.some(
        (game) =>
          game?.code_betflik &&
          game.code_betflik !== "" &&
          game.code_betflik !== null
      )) ??
    false;

  return {
    data: data || null,
    isUfaEnabled,
    isBetflixEnabled,
    isLoading: !error && !data,
    isError: error,
  };
}
